import React, { useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import Grid from 'components/reusables/Grid';
import { loading } from '@beewise/react-utils';
import { faSync } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchAllBhomesErrors } from './actions';
import { getFilteredErrors } from './selectors';
import { FETCH_ALL_BHOMES_ERRORS, FETCH_UPDATE_BHOME_ERROR } from './actionTypes';

const Errors = () => {
    const [startDate, setStartDate] = useState(dayjs(new Date()).subtract(3, 'hour').toDate());
    const [endDate, setEndDate] = useState(dayjs(new Date()).endOf('day').toDate());

    const dispatch = useDispatch();
    const errors = useSelector(getFilteredErrors);

    const handleFetchErrorsData = () => {
        if (startDate && endDate) {
            dispatch(fetchAllBhomesErrors(dayjs(startDate).valueOf() / 1000, dayjs(endDate).valueOf() / 1000));
        }
    };

    useEffect(() => {
        handleFetchErrorsData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columnDefs = useMemo(
        () => [
            {
                headerName: 'ID',
                field: 'message_id',
                maxWidth: 200,
            },
            {
                headerName: 'Error',
                field: 'error',
                wrapText: true,
                autoHeight: true,
            },
            {
                headerName: 'Alert ID',
                field: 'alert_id',
                wrapText: true,
                autoHeight: true,
            },
            {
                headerName: 'Alert Name',
                field: 'alert_name',
                wrapText: true,
                autoHeight: true,
            },
            {
                headerName: 'Alert Type',
                field: 'alert_type',
                wrapText: true,
                autoHeight: true,
            },
            {
                headerName: 'Created At',
                field: 'created_at',
                valueFormatter: params => new Date(params.data.created_at).toLocaleString(),
                comparator: (valueA, valueB) => dayjs(valueA).unix() - dayjs(valueB).unix(),
                maxWidth: 200,
            },
            {
                headerName: 'BH ID',
                field: 'bhome_id',
                maxWidth: 110,
            },
            {
                headerName: 'Bee Home State',
                field: 'bhome_status',
                wrapText: true,
                autoHeight: true,
            },
            {
                headerName: 'Combined Posencoders',
                field: 'combinedposencoders',
                wrapText: true,
                autoHeight: true,
            },
        ],
        []
    );

    const getRowId = useCallback(({ data }) => `${data.id}`, []);

    return (
        <div className="errors">
            <FontAwesomeIcon className="icon-sync" icon={faSync} onClick={handleFetchErrorsData} />
            <div className="errors-pickers">
                <span>From:</span>
                <DatePicker
                    selected={startDate}
                    onChange={setStartDate}
                    withPortal
                    maxDate={endDate}
                    showTimeSelect
                    timeFormat="HH:mm"
                    dateFormat="dd/MM/yyyy HH:mm"
                    onCalendarClose={handleFetchErrorsData}
                />
                <span>To:</span>
                <DatePicker
                    selected={endDate}
                    onChange={setEndDate}
                    withPortal
                    minDate={startDate}
                    maxDate={new Date()}
                    showTimeSelect
                    timeFormat="HH:mm"
                    dateFormat="dd/MM/yyyy HH:mm"
                    onCalendarClose={handleFetchErrorsData}
                />
            </div>
            <Grid rowData={errors} getRowId={getRowId} columnDefs={columnDefs} filePrefix="errors-" />
        </div>
    );
};

export default loading([FETCH_ALL_BHOMES_ERRORS.default, FETCH_UPDATE_BHOME_ERROR.default])(Errors);
