import { isBoolean, isNaN, isObject, parseInt } from 'lodash-es';
import dayjs from 'dayjs';
import { isInLimitedBhomeBayRange, isProduction } from 'utils';
import StatusRenderer from '../components/StatusRenderer';
import NoteRenderer from '../components/NoteRenderer';

const formatDateAndTimestamp = timestamp => {
    if (!timestamp || timestamp === '0') return { formattedDate: '', timestamp: 0 };

    let date;
    if (typeof timestamp === 'string' && !isNaN(Date.parse(timestamp))) {
        date = new Date(timestamp);
    } else if (typeof timestamp === 'number' || !isNaN(parseInt(timestamp, 10))) {
        date = new Date(parseInt(timestamp, 10) * 1000);
    } else {
        return { formattedDate: '', timestamp: 0 };
    }

    return { formattedDate: date.toLocaleString(), timestamp: date.getTime() };
};

const getBhomeCountry = bhome => {
    let country = '';
    if ((bhome?.lat && bhome.lng) || (bhome?.gps?.latitude && bhome?.gps?.longitude)) {
        let lat;
        let lng;
        if (bhome?.gps?.latitude && bhome?.gps?.longitude) {
            lat = bhome?.gps?.latitude;
            lng = bhome?.gps?.longitude;
        } else {
            lat = bhome?.lat;
            lng = bhome?.lng;
        }
        // TODO: add more countries based on some geocode API
        country = lat >= 31 && lat <= 34 && lng >= 30 && lng <= 45 ? 'IL' : 'US';
    }
    return country;
};

const getLastSeenOnline = (bhome, connected) => {
    if (isBoolean(connected) && connected) {
        return Date.now();
    }

    if (bhome?.disconnected_at && bhome?.connected_at) {
        return dayjs(bhome?.disconnected_at).diff(dayjs(bhome?.connected_at)) ? bhome?.disconnected_at : Date.now();
    }

    return bhome?.connected_at ? Date.now() : bhome?.disconnected_at;
};

export const getDataToDisplay = ({ bhomes, errors, data, connectivity, filters, isBayUser, selectedBhomeIds }) => {
    const useFilter = !!filters?.length;
    const filter = {};

    if (filters?.includes('onlineShown')) {
        filter.connected = true;
    }

    const itemsToFilter = bhomes.reduce((acc, bhome) => {
        if (isBayUser && !isInLimitedBhomeBayRange(bhome?.id)) {
            return acc;
        }

        if (isProduction && bhome.id < 10000) {
            return acc;
        }

        if (filters?.includes('markedOnly') && !selectedBhomeIds?.includes(bhome.id)) {
            return acc;
        }

        const connected = connectivity?.find(item => item.id === bhome.id)?.connected;
        const bhomeError = errors?.find(item => item.bhome_id === bhome.id);
        let currentActivity = data?.messages?.find(item => item.bhome_id === bhome.id);
        if (currentActivity?.command) {
            currentActivity = `${currentActivity?.command} ${new Date(currentActivity?.sent_at).toLocaleString()}`;
        }

        const yardOrRanchName =
            bhome.ranch_name && bhome.yard_name
                ? `${bhome.ranch_name}/${bhome.yard_name}`
                : bhome.ranch_name || bhome.yard_name || 'NA';

        const country = getBhomeCountry(bhome);
        const lastSeenOnline = getLastSeenOnline(bhome, connected);

        acc.push({
            id: bhome.id,
            status: bhome.status,
            operationalState: bhome.operational_state,
            connected: isBoolean(connected) ? connected : "IoT Device doesn't exist",
            soc: bhome?.solar_data?.soc,
            middlewareVersion:
                bhome?.configuration?.middleware_version ||
                bhome?.settings?.machine_specific?.general_settings?.middleware_version ||
                bhome?.settings?.general?.middleware_version,
            iccid: bhome?.sys_info?.modem?.sim_card_data?.iccid ?? '',
            country,
            region: bhome?.region_name || bhome?.coordsState || '-',
            error: isObject(bhomeError?.error) ? JSON.stringify(bhomeError?.error) : bhomeError?.error,
            currentActivity,
            note: bhome.note,
            isDoorOpen: bhome?.interlocks?.doors === 'open',
            noteUpdatedAt: bhome.note_updated_at,
            companyName: bhome.company_name,
            yardOrRanchName,
            initializedAt: bhome.initialized_at,
            networkOperator: bhome?.sys_info?.modem?.sim_card_data?.network_operator ?? '',
            visit: bhome?.mode?.visit || false,
            transportation: bhome?.mode?.transportation || false,
            super: bhome?.mode?.super || false,
            feeding: bhome?.settings?.ui?.activity_manager?.auto_feed_policy
                ? `${bhome?.settings?.ui?.activity_manager?.auto_feed_policy?.auto_feed_enabled} /
        ${bhome?.settings?.ui?.activity_manager?.auto_feed_policy?.auto_feed_cycle_days}`
                : '',
            autoScan: bhome?.settings?.ui?.activity_manager?.auto_scan_policy
                ? `${bhome?.settings?.ui?.activity_manager?.auto_scan_policy?.auto_scan_enabled} /
                ${bhome?.settings?.ui?.activity_manager?.auto_scan_policy?.auto_scan_cycle_days}`
                : '',
            autoCountBees: bhome?.settings?.ui?.activity_manager?.auto_count_bees_policy
                ? `${bhome?.settings?.ui?.activity_manager?.auto_count_bees_policy?.auto_count_bees_enabled} /
            ${bhome?.settings?.ui?.activity_manager?.auto_count_bees_policy?.auto_count_bees_cycle_days}`
                : '',
            autoIrScan:
                bhome?.settings?.ui?.activity_manager?.auto_thermal_inspection_policy?.auto_thermal_scan_enabled ||
                false,
            rsrp: bhome?.sys_info?.cellular_reception,
            lastSeenOnline,
            lastLocationUpdate: bhome?.gps?.timestamp,
        });
        return acc;
    }, []);

    if (useFilter) {
        return itemsToFilter.filter(item => {
            // eslint-disable-next-line no-restricted-syntax
            for (const key in filter) {
                if (item[key] === undefined || item[key] !== filter[key]) return false;
            }
            return true;
        });
    }

    return itemsToFilter;
};

export const columnDefs = [
    { headerName: '#', valueGetter: 'node.rowIndex + 1', maxWidth: 80, sortable: false, suppressMenu: true },
    {
        headerName: 'ID',
        field: 'id',
        maxWidth: 80,
    },
    {
        headerName: 'Status (last known)',
        field: 'status',
        minWidth: 120,
    },
    {
        headerName: 'MW Version',
        field: 'middlewareVersion',
    },
    {
        headerName: 'Country',
        field: 'country',
        minWidth: 80,
    },
    {
        headerName: 'Network',
        field: 'networkOperator',
        minWidth: 80,
    },
    {
        headerName: 'Yard/Ranch',
        field: 'yardOrRanchName',
        minWidth: 80,
    },
    {
        headerName: 'Latest error',
        field: 'error',
    },
    {
        headerName: 'Online',
        field: 'connected',
        cellRenderer: StatusRenderer,
        maxWidth: 110,
    },
    {
        headerName: 'Door Open',
        field: 'isDoorOpen',
        cellRenderer: StatusRenderer,
        cellRendererParams: {
            reverse: true,
        },
    },
    {
        headerName: 'Initialized At',
        field: 'initializedAt',
        valueFormatter: params =>
            params.data.initialized_at ? new Date(params.data.initialized_at).toLocaleString() : '',
        comparator: (valueA, valueB) => dayjs(valueA).unix() - dayjs(valueB).unix(),
        maxWidth: 200,
    },
    {
        headerName: 'SOC',
        field: 'soc',
        minWidth: 80,
        valueFormatter: params => (!Number.isNaN(Number(params.data.soc)) ? Number(params.data.soc)?.toFixed(2) : ''),
    },
    {
        headerName: 'Current Activity',
        field: 'currentActivity',
        minWidth: 120,
    },
    {
        headerName: 'Note',
        field: 'note',
        cellRenderer: NoteRenderer,
        minWidth: 200,
        cellClass: 'note-renderer-cell',
    },
    {
        headerName: 'Company',
        field: 'companyName',
        minWidth: 100,
    },
];

const defaultDateComparatorHandler = (filterLocalDateAtMidnight, cellValue, format = null) => {
    if (!cellValue) {
        return 0;
    }
    const cellDate = format ? dayjs(cellValue, format) : dayjs(cellValue);
    if (cellDate.diff(dayjs(filterLocalDateAtMidnight)) < 0) {
        return -1;
    } else if (cellDate.diff(dayjs(filterLocalDateAtMidnight)) > 0) {
        return 1;
    }
    return 0;
};

export const columnMonitoringDefs = [
    {
        headerName: '#',
        field: '#',
        valueGetter: 'node.rowIndex + 1',
        maxWidth: 80,
        sortable: false,
        suppressMenu: true,
    },
    {
        headerName: 'ID',
        field: 'id',
        minWidth: 120,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
    },
    {
        headerName: 'Status (last known)',
        field: 'status',
        minWidth: 180,
    },
    {
        headerName: 'Operational state',
        field: 'operationalState',
        minWidth: 150,
    },
    {
        headerName: 'MW Version',
        field: 'middlewareVersion',
        minWidth: 150,
    },
    {
        headerName: 'Country',
        field: 'country',
        minWidth: 120,
    },
    {
        headerName: 'Region',
        field: 'region',
        minWidth: 150,
    },
    {
        headerName: 'Network',
        field: 'networkOperator',
        minWidth: 150,
    },
    {
        headerName: 'Yard/Ranch',
        field: 'yardOrRanchName',
        minWidth: 150,
    },
    {
        headerName: 'Latest error',
        field: 'error',
        minWidth: 150,
    },
    {
        headerName: 'Online',
        field: 'connected',
        cellRenderer: StatusRenderer,
        minWidth: 120,
    },
    {
        headerName: 'Door Open',
        field: 'isDoorOpen',
        cellRenderer: StatusRenderer,
        cellRendererParams: {
            reverse: true,
        },
        minWidth: 150,
    },
    {
        headerName: 'Initialized At',
        field: 'initializedAt',
        filter: 'agDateColumnFilter',
        filterParams: {
            comparator: defaultDateComparatorHandler,
        },
        valueFormatter: params =>
            params.data.initializedAt ? new Date(params.data.initializedAt).toLocaleString() : '',
        comparator: (valueA, valueB) => dayjs(valueA).unix() - dayjs(valueB).unix(),
        minWidth: 150,
    },
    {
        headerName: 'SOC',
        field: 'soc',
        minWidth: 100,
        filter: 'agNumberColumnFilter',
        valueFormatter: params => (!Number.isNaN(Number(params.data.soc)) ? Number(params.data.soc)?.toFixed(2) : ''),
    },
    {
        headerName: 'Current Activity',
        field: 'currentActivity',
        minWidth: 170,
    },
    {
        headerName: 'Note',
        field: 'note',
        cellRenderer: NoteRenderer,
        minWidth: 180,
        cellClass: 'note-renderer-cell',
    },
    {
        headerName: 'Company',
        field: 'companyName',
        minWidth: 150,
    },
    {
        headerName: 'Visit',
        field: 'visit',
        cellRenderer: StatusRenderer,
        minWidth: 100,
    },
    {
        headerName: 'Transportation',
        field: 'transportation',
        cellRenderer: StatusRenderer,
        minWidth: 130,
    },
    {
        headerName: 'Super',
        field: 'super',
        cellRenderer: StatusRenderer,
        minWidth: 130,
    },
    {
        headerName: 'Feeding',
        field: 'feeding',
        minWidth: 130,
    },
    {
        headerName: 'Auto scan',
        field: 'autoScan',
        minWidth: 130,
    },
    {
        headerName: 'Auto count bees',
        field: 'autoCountBees',
        minWidth: 160,
    },
    {
        headerName: 'Auto IR scan',
        field: 'autoIrScan',
        minWidth: 140,
        cellRenderer: StatusRenderer,
    },
    {
        headerName: 'Rsrp',
        field: 'rsrp',
        filter: 'agNumberColumnFilter',
        minWidth: 100,
    },
    {
        headerName: 'Last seen online',
        field: 'lastSeenOnline',
        filter: 'agDateColumnFilter',
        filterParams: {
            comparator: defaultDateComparatorHandler,
        },
        valueFormatter: params =>
            params.data.lastSeenOnline ? new Date(params.data.lastSeenOnline).toLocaleString() : '',
        comparator: (valueA, valueB) => dayjs(valueA).unix() - dayjs(valueB).unix(),
        minWidth: 180,
    },
    {
        headerName: 'Last location update',
        field: 'lastLocationUpdate',
        filter: 'agDateColumnFilter',
        filterParams: {
            comparator: (filterLocalDateAtMidnight, cellValue) =>
                defaultDateComparatorHandler(filterLocalDateAtMidnight, cellValue, 'X'),
        },
        valueFormatter: params => formatDateAndTimestamp(params.data.lastLocationUpdate).formattedDate,
        comparator: (valueA, valueB) =>
            formatDateAndTimestamp(valueA).timestamp - formatDateAndTimestamp(valueB).timestamp,
        minWidth: 180,
    },
];
